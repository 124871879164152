import React from 'react';

export interface AboutProps {
  prop?: string;
}

export function About({prop = 'default value'}: AboutProps) {
  return (
    <React.Fragment>
      <div className="container col-xxl-12 px-4 py-md-2 text-center text-lg-start">
        <div className="row align-items-center g-5 py-5">
          <div className="col-12">
            <h3 className="display-5 fw-bold text-body-emphasis lh-3 mb-3">Our Story</h3>
            <p className="lead">
              Cliqeur started as a side project in 2020 to solve a particular problem in web development — how to test web page rendering and interactions with ease without the use of Internet Browsers extensions. The journey was filled with numerous technical challenges, from creating a robust and intuitive interface to ensuring compatibility across various web technologies. Despite these hurdles, the idea became a real product.
            </p>
          </div>
          {/* <div className="col-12 col-lg-7 mt-0">
            <img src={process.env.PUBLIC_URL + '/cliqeur_screenshot_2.png'} className="d-block mx-lg-auto img-fluid border rounded-1 shadow-lg" alt="Bootstrap Themes" width="700" height="500" loading="lazy"/>
          </div> */}
          
        </div>
      </div>
      <div className="container bg-body-tertiary rounded-3">
        <div className="container px-4 py-2 col-xxl-12 text-center text-lg-start">
          <div className="row flex-row-reverse align-items-center g-5 py-5">
            
            <div className="col-lg-6">
              <h1 className="display-6 fw-bold text-body-emphasis lh-1 mb-3">Founder</h1>
              <p className="lead">
                Mokrane Moali, Founder of Cliqeur, has been passionate about solving problems through technology. With a background in software development and a keen understanding of the challenges faced by developers, Mokrane embarked on a mission to create a tool that simplifies web testing. His dedication and innovative approach have been the driving force behind Cliqeur.
              </p>
            </div>
            <div className="col-12 col-lg-6">
              <img src={process.env.PUBLIC_URL + '/cliqeur_founder.jpg'} className="d-block mx-lg-auto img-fluid border rounded-5 shadow-lg" alt="Bootstrap Themes" width="700" height="500" loading="lazy"/>
            </div>
          </div>
        </div>
      </div>
      <div className="container col-xxl-12 px-4 py-5 text-center text-lg-start">
        <div className="row align-items-center g-5 py-0">
          <div className="col-12">
            <h1 className="display-5 fw-bold text-body-emphasis lh-1 mb-3">Vision</h1>
            <p className="lead">At Cliqeur, we are committed to continuously evolving and improving our product to meet the needs of our users. Our vision is to become the go-to tool for web testing, empowering businesses and individuals to achieve their web development goals with ease and efficiency.</p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
