import './App.css';
import { Contact } from '../Contact/Contact.tsx';
import { Product } from '../Product/Product.tsx';
import { Pricing } from '../Pricing/Pricing.tsx';
import { Privacy } from '../Privacy/Privacy.tsx';
import { Terms } from '../Terms/Terms.tsx';
import { BrowserRouter, Routes, Route, Link, NavLink } from 'react-router-dom';
import React, { createContext, useState, useEffect, ReactNode } from 'react';
import { getConfiguration } from '../../Configuration.tsx';
import { GlobalLoading } from '../GlobalLoading/GlobalLoading.tsx';
import { Download } from '../Download/Download.tsx';
import { About } from '../About/About.tsx';

interface AppContextProps {
  isLoading: boolean;
  setIsLoading: (loading: boolean) => void;
}

export const AppContext = createContext<AppContextProps | undefined>(undefined);

export const AppProvider = ({ children }: { children: ReactNode }) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <AppContext.Provider value={{ isLoading, setIsLoading }}>
      {children}
    </AppContext.Provider>
  );
};

export const App = () =>{

  const [config, setConfig] = useState<any>({});

  useEffect(() => {
    let mounted = true;
    getConfiguration().then((config) => {
      if (mounted) {
        setConfig(config);
      }
    });
    
  }, []);

  return (
    <AppProvider>
      <BrowserRouter>
      
          <header>
              <div className="d-flex flex-column flex-md-row align-items-center pb-3 mb-4 border-bottom">
                <a href="/" className="d-flex align-items-center text-decoration-none">
                  <img className="me-1" src={process.env.PUBLIC_URL + '/logo.png'} alt="Cliqeur" width="32" height="32"/>
                  <img className="me-3" src={process.env.PUBLIC_URL + '/logo_word.png'} alt="Cliqeur" height="24"/>
                </a>
        
                <nav className="d-inline-flex mt-2 mt-md-0 ms-md-auto">
                  <NavLink className={(navData) => !navData.isActive ? 'me-3 py-2 text-decoration-none link-body-emphasis' : 'me-3 py-2 text-decoration-none border-bottom border-2 border-primary' } to="/">Product</NavLink>
                  <NavLink className={(navData) => !navData.isActive ? 'me-3 py-2 text-decoration-none link-body-emphasis' : 'me-3 py-2 text-decoration-none border-bottom border-2 border-primary' } to="/download">Download</NavLink>
                  <NavLink className={(navData) => !navData.isActive ? 'me-3 py-2 text-decoration-none link-body-emphasis' : 'me-3 py-2 text-decoration-none border-bottom border-2 border-primary' } to="/pricing">Pricing</NavLink>
                  <NavLink className={(navData) => !navData.isActive ? 'me-3 py-2 d-none d-sm-block text-decoration-none link-body-emphasis' : 'me-3 py-2 d-none d-sm-block text-decoration-none border-bottom border-2 border-primary' } to="/about">About us</NavLink>
                  <NavLink className={(navData) => !navData.isActive ? 'me-3 py-2 d-none d-sm-block text-decoration-none link-body-emphasis' : 'me-3 py-2 d-none d-sm-block text-decoration-none border-bottom border-2 border-primary' } to="/contact">Contact</NavLink>
                  <Link className="me-3 py-2 btn btn-primary text-decoration-none link-body-inverse" to={config.portal+'/signin'}>Login</Link>
                </nav>
              </div>
          </header>
          <main>
            <Routes>
              <Route path='/' element={<Product/>}></Route>
              <Route path='/download' element={<Download/>}></Route>
              <Route path='/contact' element={<Contact/>}></Route>
              <Route path='/pricing' element={<Pricing/>}></Route>
              <Route path='/privacy' element={<Privacy/>}></Route>
              <Route path='/about' element={<About/>}></Route>
              <Route path='/terms' element={<Terms/>}></Route>
            </Routes>
          </main>
          <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 mt-5 border-top">
            <ul className="col-12 col-md-4 mb-md-0 mb-md-0 justify-content-center justify-content-md-start footer-text text-body-secondary list-unstyled small text-muted" style={{fontSize: '9px'}}>
              <li className="text-center text-md-start">
                Cliqeur&reg;<br />
                Prominent Bytes, Inc. &copy; 2023-2024<br />
                v{config.version} 
                { config.env !== null && config.env !== undefined && config.env.length > 0 &&
                  <span className="badge text-bg-warning text-small mx-1">{ config.env }</span>
                }
              </li>
              <li>
                <ul className="list-inline text-center text-md-start">
                  <li className="list-inline-item"><Link className="text-decoration-none" to="/privacy">Privacy</Link></li>
                  <li className="list-inline-item"><Link className="text-decoration-none" to="/terms">Terms</Link></li>
                  <li className="list-inline-item"><Link className="text-decoration-none" to="/contact">Contact</Link></li>
                  <li className="list-inline-item"><Link className="text-decoration-none" to="/about">About us</Link></li>
                </ul>
              </li>
            </ul>
            <a href="/" className="col-12 col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto text-decoration-none">
              <img className="bi me-2" src={process.env.PUBLIC_URL + '/logo.png'} alt="Cliqeur" width="32" height="32"/>
            </a>
            <ul className="nav col-12 col-md-4 justify-content-center justify-content-md-end list-unstyled d-flex">
              <li className="ms-3"><a className="text-body-secondary" href="https://x.com/Cliqeur1"><svg className="bi" width="24" height="24"><use xlinkHref="#twitter"/></svg></a></li>
              <li className="ms-3"><a className="text-body-secondary" href="https://www.instagram.com/cliqeur1"><svg className="bi" width="24" height="24"><use xlinkHref="#instagram"/></svg></a></li>
              <li className="ms-3"><a className="text-body-secondary" href="https://www.facebook.com/cliqeur1"><svg className="bi" width="24" height="24"><use xlinkHref="#facebook"/></svg></a></li>
              <li className="ms-3"><a className="text-body-secondary" href="https://www.youtube.com/@cliqeur"><svg className="bi" width="24" height="24"><use xlinkHref="#youtube"/></svg></a></li>
              <li className="ms-3"><a className="text-body-secondary" href="https://www.reddit.com/r/cliqeur/"><svg className="bi" width="24" height="24"><use xlinkHref="#reddit"/></svg></a></li>
              <li className="ms-3"><a className="text-body-secondary" href="https://www.linkedin.com/company/cliqeur/"><svg className="bi" width="24" height="24"><use xlinkHref="#linkedin"/></svg></a></li>
              <li className="ms-3"><a className="text-body-secondary" href="https://www.tiktok.com/@cliqeur"><svg className="bi" width="24" height="24"><use xlinkHref="#tiktok"/></svg></a></li>
            </ul>
          </footer>
          <GlobalLoading />
      </BrowserRouter>
    </AppProvider>
  );
}